import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

// components
import Layout from 'src/layouts/BaseLayout'
import Highlight from 'src/components/Layout/NewHighlight'
import InvestmentTable from 'src/components/InvestmentTable'
import CallToAction from 'src/components/CallToAction'
import Link from 'components/GatsbyLinkWrapper'
import SectionTopics from 'src/components/SectionTopics'

// assets
import pageContext from './pageContext.json'
import usePageQuery from './pageQuery'
import { SideBar, ContentWrapper } from 'src/layouts/BaseLayout/style'

const LciIpca = () => {
  const data = usePageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()

  const link = '/pra-voce/investimentos/renda-fixa/mercado-secundario/'

  return (
    <Layout pageContext={pageContext}>
      <Highlight highlightContext={pageContext} bannerDesktop={data.bannerDesktop.fluid} bannerMobile={data.bannerMobile.fluid} />
      <ContentWrapper>
        <section className='pt-5 pb-lg-5 bg-white'>
          <div className='container'>
            <div className='row align-items-center'>
              <div className='col col-lg-8'>
                <h3>Rentabilidade garantida e protegida da inflação</h3>
                <p>Com prazos a partir de 3 anos, a LCI indexada pelo IPCA proporciona alta rentabilidade e segurança em relação às oscilações da inflação (pois é remunerada a uma taxa fixa acima dela). Além disso, é isenta de taxas e de IR. Um investimento seguro, garantido pelo FGC e lastreado por bens imóveis financiados pelo próprio Banco Inter.</p>
                <SectionTopics title='É ideal para' styles='fs-md-40'>
                  <ul className='list-unstyled'>
                    <li>Investidores com perfil conservador</li>
                    <li>Quem pode deixar o dinheiro aplicado por muito tempo</li>
                    <li>Garantir ótima rentabilidade</li>
                  </ul>
                </SectionTopics>
                <p>A LCI IPCA não tem liquidez diária. Mas se você precisar do valor investido antes do prazo, pode contar com{' '}
                  <Link
                    to='/pra-voce/investimentos/renda-fixa/mercado-secundario/'
                    onClick={() => {
                      sendDatalayerEvent({
                        section: 'dobra_02',
                        element_action: 'click button',
                        element_name: 'Mercado Secundário',
                        section_name: 'Rentabilidade garantida e protegida da inflação',
                        redirect_url: link,
                      })
                    }}
                  >
                    Mercado Secundário
                  </Link>
                </p>
                <div className='col-12 px-0'>
                  <InvestmentTable title='Rentabilidade LCI IPCA' investment='LCI IPCA FINAL' selectdays='true' />
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className='container pb-4'>
          <SideBar className='col-12 col-lg-4 px-0 px-lg-3'>
            <div className='position-sticky'>
              <CallToAction
                section='dobra_2'
                elementAction='click button'
                elementName='Abra sua conta e invista'
                sectionName='Rentabilidade garantida e protegida da inflação'
                text='Faça uma simulação'
                chat='true'
                account='true'
              />
            </div>
          </SideBar>
        </div>
      </ContentWrapper>
    </Layout>
  )
}

export default LciIpca
