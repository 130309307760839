/* eslint-disable no-case-declarations */
/* store */
export const store = {
  valueFrom: 0,
  valueTo: 0,
  upperCurrentItem: 0,
  bottomCurrentItem: 0,
  percentageValue: '',
  investmentValues: [],
  allInvestmentsListed: [],
  numberOfDays: [],
  days: 0,
}

/* types */
const UPDATE_UPPER_VALUES = 'UPDATE_UPPER_VALUES'
const UPDATE_RENTABILITY_DAYS = 'UPDATE_RENTABILITY_DAYS'
const SET_ELEMENTS_ASC_ORDER = 'SET_ELEMENTS_ASC_ORDER'

/* reducer */
const reducer = (state = store, action) => {
  switch (action.type) {
    case UPDATE_UPPER_VALUES:
      return {
        ...state,
        valueFrom: action.payload.valueFrom,
        valueTo: action.payload.valueTo,
        percentageValue: action.payload.percentageValue,
        upperCurrentItem: action.payload.upperCurrentItem,
      }
    case UPDATE_RENTABILITY_DAYS:
      const investmentValues = action.payload.investmentValues
      return {
        ...state,
        days: action.payload.days,
        bottomCurrentItem: action.payload.bottomCurrentItem,
        investmentValues: investmentValues,
        percentageValue: action.investmentType === 'LCI DI'
          ? investmentValues[state.upperCurrentItem].percentage
          : investmentValues[state.upperCurrentItem].rate,
      }
    case SET_ELEMENTS_ASC_ORDER:
      const daysOrdered = action.payload.numberOfDays.sort((a, b) => a - b)
      const allInvestmentsOrdered = action.payload.allInvestmentsListed.sort((a, b) => a.days - b.days)
      const { percentage, rate, valueFrom, valueTo } = allInvestmentsOrdered[0].percentages[0]
      return {
        ...state,
        numberOfDays: daysOrdered,
        allInvestmentsListed: allInvestmentsOrdered,
        days: daysOrdered[0],
        percentageValue: action.investmentType !== 'LCI IPCA FINAL' ? percentage : rate,
        valueFrom,
        valueTo,
        investmentValues: allInvestmentsOrdered[0].percentages,
      }
  }
}

/* actions */
export const updateUpperValues = (valueFrom, valueTo, percentageValue, upperCurrentItem) => ({
  type: UPDATE_UPPER_VALUES,
  payload: {
    valueFrom,
    valueTo,
    percentageValue,
    upperCurrentItem,
  },
})

export const setElementsAscendingOrder = (numberOfDays, allInvestmentsListed, investment) => ({
  type: SET_ELEMENTS_ASC_ORDER,
  payload: {
    numberOfDays,
    allInvestmentsListed,
  },
  investmentType: investment,
})

export const setRentabilityDays = (days, bottomCurrentItem, investmentValues, investment) => ({
  type: UPDATE_RENTABILITY_DAYS,
  payload: {
    days,
    bottomCurrentItem,
    investmentValues,
  },
  investmentType: investment,
})

export default reducer
