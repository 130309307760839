/* eslint-disable no-console */
import React, { useReducer, useEffect } from 'react'
import { translate } from 'react-i18next'
import { Utility } from 'inter-site-components'
import * as URLs from 'config/api/Urls'
import './investmentTable.scss'

// reducer store and actions
import reducer, {
  store,
  updateUpperValues,
  setElementsAscendingOrder,
  setRentabilityDays,
} from './reducer'

const InvestmentTable = ({ title, investment, selectdays }) => {
  const [ state, dispatch ] = useReducer(reducer, store)

  const {
    valueFrom,
    valueTo,
    percentageValue,
    upperCurrentItem,
    bottomCurrentItem,
    days,
    numberOfDays,
    investmentValues,
    allInvestmentsListed,
  } = state

  const isLCIIPCA = investment === 'LCI IPCA FINAL'
  const lastButtonClicked = upperCurrentItem === investmentValues.length - 1

  useEffect(() => {
    async function getInvestment () {
      try {
        const tableData = await Utility.request({
          url: URLs.TABLE_INVESTMENTS,
        }, (20000))

        const numberOfDays = []
        const allInvestmentsListed = []

        tableData.map((item, index) => {
          const investmentType = item.modalityName
          if (!selectdays && investmentType.includes(investment)) {
            allInvestmentsListed.push(item)
            numberOfDays.push(item.days)
          } else if (selectdays && investmentType.includes(investment)) {
            if (investmentType.includes('90 DIAS')) {
              allInvestmentsListed.push({ ...item, days: 90 })
              numberOfDays.push(90)
            } else {
              allInvestmentsListed.push(item)
              numberOfDays.push(item.days)
            }
          }

          if (index === tableData.length - 1) dispatch(setElementsAscendingOrder(numberOfDays, allInvestmentsListed, investment))
        })
      } catch (err) {
        throw new Error(err)
      }
    }
    getInvestment()
  }, [])

  const getBottomRentabilityData = (index) => {
    const bottomRentabilityData = []
    allInvestmentsListed.map((item, indexRow) => indexRow === index && bottomRentabilityData.push(item))

    return bottomRentabilityData
  }

  const updateValues = (data, index) => {
    const { valueFrom, valueTo, percentage, rate } = data
    const upperCurrentItem = index
    dispatch(updateUpperValues(valueFrom, valueTo, investment !== 'LCI IPCA FINAL' ? percentage : rate, upperCurrentItem))
  }

  const updateRentabilityDays = (numberOfDays, index) => {
    const { percentages } = getBottomRentabilityData(index)[0]
    dispatch(setRentabilityDays(numberOfDays, index, percentages, investment))
  }

  return (
    <div className='col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2 box-rentabilidade'>
      <h4 className='text-gray--base'>{title}</h4>
      <div className='mt-4'>
        <p className='text-gray--600'>Selecione a faixa do valor a ser investido</p>
        <div className='progress'>
          {investmentValues.map((item, index) => {
            return (
              <button
                key={`button-${index}`}
                className={`${upperCurrentItem === index ? 'active' : ''} button-progress-bar`}
                onClick={() => updateValues(item, index)}
              />
            )
          })}
        </div>
        <div className='col-12 mb-3'>
          {lastButtonClicked
            ? <p className='text-gray--600'>acima de <span className='text-orange--base fs-22 fs-lg-30 fw-700'>1 milhão</span></p>
            : <p className='text-gray--600'>de <span className='text-orange--base fs-22 fs-lg-30 fw-700'>{`R$ ${Utility.numberToReal(valueFrom)}`}</span> <span className='d-block d-lg-inline fw-100'>a <span className='text-orange--base fs-22 fs-lg-30 fw-700'>{`R$ ${Utility.numberToReal(valueTo)}`}</span></span></p>
          }
        </div>
        {selectdays &&
          <>
            <div className='col-12 mb-5'>
              <p className='my-5 text-gray--600'>Selecione o prazo</p>
            </div>
            <div className='progress'>
              {numberOfDays.map((item, index) => {
                return (
                  <button
                    key={`button-${index}`}
                    className={`${bottomCurrentItem === index ? 'active' : ''} button-progress-bar`}
                    onClick={() => updateRentabilityDays(item, index)}
                  />
                )
              })}
            </div>
            <div className='col-12 mb-5'>
              <p className='text-gray--600'><span className='text-orange--base fs-22 fs-lg-30 fw-700'>{days}</span> dias</p>
            </div>
          </>
        }
        <div className='col-12 mb-4'>
          <p className='text-gray--600'>A rentabilidade será</p>
          <span className='text-green--base fw-700 fs-22 fs-lg-30'>{`${percentageValue}%${investment !== 'LCI IPCA FINAL' ? ' do CDI' : ''}`}</span>
          {isLCIIPCA && <span className='text-green--base fs-22 fs-lg-30'> + IPCA</span>}
        </div>
        <p className='mt-2 text-gray--600 fs-14 text-left'>* Taxas sujeitas à alteração.</p>
      </div>
    </div>
  )
}

InvestmentTable.defaultProps = {
  title: '',
  investment: '',
}

export default translate('Components')(InvestmentTable)
